<template>
  <section class="other-amount mt-5 mb-0">
    <base-text-field
      autofocus
      required
      hide-details
      step="0.01"
      min="0"
      max="99_999_999"
      :maxlength="8"
      type="number"
      class="other-amount__input"
      v-model="tipAmount"
      :error="!!errorMessage"
      @input="validateInput"
      @keypress="handleKeyPress"
    >
      <template #append-outer><h4 class="currency-symbol">€</h4></template>
    </base-text-field>
    <p class="other-amount__error red--text">{{ errorMessage }}</p>
  </section>
</template>

<script>
import { rulesTipAmount } from "@/helpers";

export default {
  name: "CustomAmountTipInput",
  inject: ["handleAmountOtherTipIsInvalid"],
  data() {
    return {
      tipAmount: "",
      errorMessage: "",
    };
  },
  created() {
    this.tipAmount =
      this.getAmountTipSelected === 0 ? "" : this.getAmountTipSelected;
  },
  computed: {
    getOptTipSelected() {
      return this.$store.getters["cart/getTipInfo"].option;
    },
    getAmountTipSelected() {
      return this.$store.getters["cart/getTipInfo"].amount;
    },
    inputIsValid() {
      return !!this.errorMessage;
    },
    getIntegerPart() {
      return this.tipAmount.split(".")[0].slice(0, 8);
    },
    getIsDecimal() {
      return this.tipAmount.split(".")[1];
    },

    getDecimalPart() {
      return this.isDecimal && this.tipAmount.split(".")[1].slice(0, 2);
    },
  },
  methods: {
    handleKeyPress(event) {
      const { key, ctrlKey, altKey, metaKey } = event;
      if (key.length !== 1 || ctrlKey || altKey || metaKey) return;

      const forbiddenCharactersRegex = /[eE\-+]/;
      if (forbiddenCharactersRegex.test(key)) {
        event.preventDefault();
        return;
      }

      const { value } = event.target;
      if (key === "." && value.length === 0) return event.preventDefault();

      const isValidInput = /^[\d.]$/.test(key);

      if (!isValidInput) return event.preventDefault();

      console.log(value);
      this.sanitizedValue(value);

      const isDecimal = this.getIsDecimal;
      const checkMaxIntegerPartLength = this.getIntegerPart.length === 8;
      if (
        !isDecimal &&
        checkMaxIntegerPartLength &&
        key !== "." &&
        !this.getDecimalPart
      )
        return event.preventDefault();

      const checkMaxDecimalPartLength =
        this.getDecimalPart && this.getDecimalPart.length === 2;

      if (isDecimal && checkMaxDecimalPartLength) return event.preventDefault();
    },
    validateInput() {
      this.handleChangeInputAmount(this.tipAmount);
      this.handleAmountOtherTipIsInvalid(!!this.errorMessage);
    },
    handleChangeInputAmount(amount) {
      this.checkRulesError();
      if (!!this.errorMessage) {
        this.setTipAmountStore(0);
        return;
      }

      this.setTipAmountStore(amount);
    },
    checkRulesError() {
      for (const validate of rulesTipAmount) {
        const result = validate(this.tipAmount);
        if (typeof result === "string") {
          this.errorMessage = result;
          return;
        }
      }
      this.errorMessage = "";
    },
    sanitizedValue(value) {
      const sanitizedValue = value.replace(/^(\d{0,8}(?:\.\d{0,2})?).*/, "$1");
      this.tipAmount = sanitizedValue;
      let [integerPart, decimalPart] = sanitizedValue.split(".");

      if (integerPart && integerPart.length > 8) integerPart.slice(0, 8);

      if (decimalPart && decimalPart > 2) decimalPart = decimalPart.slice(0, 1);

      this.tipAmount = decimalPart
        ? [integerPart, decimalPart].join(".")
        : integerPart;
    },
    setTipAmountStore(amount) {
      const option = this.getOptTipSelected;

      if (option === "other") {
        this.$store.dispatch("cart/setTip", { amount, option });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.other-amount {
  &__input {
    max-width: 120px;
    margin: auto;
    .currency-symbol {
      font-size: 25px;
    }
  }
  &__error {
    text-align: center;
    flex-basis: 100%;
    padding: 4px;
  }
}
</style>
